import router from '@/router'


export function goodsDetails(order) {
   const {rcharge_type} =order
switch (rcharge_type) {
    case "study_card":
        router.push({
            name:"courseDetails",
            params:{
                id:order.studycard_id
            }
        })

        break;
    case "venue":
        router.push({
            name:"serveDetails",
            params:{
                id:order.studycard_id
            }
        })
        break;
    case "need_bond":
            router.push({ name: 'courseNeedDetails', query: { id: order.studycard_id } })
            break; 
}
  }